<template>
  <div class="part panorama-image-container">
    <img class="panorama-image" :src="payload.bild.large" :alt="payload.bild.alt">
  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
};
</script>

<style lang="scss" scoped>
.panorama-image {
  width: 100%;
  min-height: px(250);
  max-height: px(250);
  object-fit: cover;
  @screen sm {
    min-height: px(350);
    max-height: px(350);
  }
  @screen md {
    min-height: px(450);
    max-height: px(450);
  }
  @screen lg {
    min-height: px(500);
    max-height: px(500);
  }
  @screen xl {
    min-height: px(600);
    max-height: px(600);
  }
}
</style>
